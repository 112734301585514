import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import * as Sentry from '@sentry/nextjs'
import { useRef, useState, useEffect } from 'react'
import Head from 'next/head'
import Image from "next/image";
import Link from 'next/link'
import { useForm } from 'react-hook-form'

import { servicePaths, serviceById } from '../queries/services'

import Phone from '../assets/phone.svg'
import Calendar from '../assets/calendar.svg'
import { useBasket } from '../providers/basket'
import { faqTemplate, serviceTemplate } from '../utils/schema'
import ApiClient from '../utils/client'
import { Conversion, recordConversion } from '../utils/conversions'
import { trackAction } from '../utils/analytics'
import { useSession } from '../providers/session'
import { useOnScreen } from '../utils/hooks/on-screen'
import { klayAction } from '../utils/formats'
import { GetStaticPaths, GetStaticProps } from 'next/types'

const Layout = dynamic(() => import('../components/layout'))
const BackgroundHeader = dynamic(
  () => import('../components/molecules/background-header')
)
const Markdown = dynamic(() => import('../components/molecules/markdown'))
const Modal = dynamic(() => import('../components/organisms/modal'))
const FAQ = dynamic(() => import('../components/molecules/faq'))
const LocalImage = dynamic(() => import('../components/atoms/image'))
const Klarna = dynamic(() => import('../components/klarna'), {
  ssr: false,
})

export default function Page(props) {
  const router = useRouter()
  const ctas = useRef(null)
  const { addItem } = useBasket()
  const { sourceParams } = useSession()
  const [state, setState] = useState({
    loading: false,
    error: false,
    success: false,
    callback: false,
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
  })

  useEffect(() => {
    if (props.content && props.content[0]) {
      try {
        const item = klayAction({
          service: props.content[0],
          type: 'view',
        })
        // @ts-ignore
        klaviyo && klaviyo.push(['track', 'Viewed Product', item])
        // @ts-ignore
        klaviyo && klaviyo.push(['trackViewedItem', item])

        window.gtag &&
          window.gtag('event', 'view_item', {
            currency: 'GBP',
            value:
              props.content[0].pricing[props.content[0].pricing.length - 1].data
                .price / 100,
            items: [
              {
                id: props.content[0].id,
                item_id: props.content[0].id,
                name: props.content[0].title,
                item_name: props.content[0].title,
                price:
                  props.content[0].pricing[props.content[0].pricing.length - 1]
                    .data.price / 100,
              },
            ],
          })

        // @ts-ignore
        window.fbq &&
          // @ts-ignore
          window.fbq('track', 'ViewContent', {
            value:
              props.content[0].pricing[props.content[0].pricing.length - 1].data
                .price / 100,
            currency: 'GBP',
            content_name: props.content[0].title,
            content_type: 'product',
            content_ids: props.content[0].id,
          })
      } catch (err) {
        console.log(err)
        Sentry.captureException(err)
      }
    }
  }, [])

  const onScreen: boolean = useOnScreen<HTMLDivElement>(ctas, '-50px')

  if (router.isFallback) {
    return <div>Loading...</div>
  }
  const service = props.content && props.content[0]

  if (!service) {
    return (
      <Layout>
        {/*<div>Oops! Service not found</div>*/}
		    <br/>
        <div>
          <p className="mb-4 font-semibold text-gray-900">
            Oops, it looks like our page is taking a little break. 
            Please refresh the page and everything will be in order in a moment. 
            Thank you for your patience!
          </p>
        </div>
      </Layout>
	  
    )
  }

  const handle = async (data) => {
    if (state.loading) {
      return false
    }
    setState({
      ...state,
      loading: true,
      error: null,
    })

    try {
      const body = JSON.stringify({
        ...data,
        first_name: data.first_name.replace(/\s/g, ''),
        last_name: data.last_name.replace(/\s/g, ''),
        email: data.email.replace(/\s/g, '').toLowerCase(),
        phone: data.phone.replace(/\s/g, ''),
        subject: `${service.title} Callback Request`,
        message: `A new ${service.title} callback request has been made for ${data.first_name}.`,
        sourceParams,
        attributes: {
          id: service.id,
          url: service.metadata.path,
          title: service.title,
        },
      })

      const r = await fetch('/api/callback', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body,
      })

      // if (sourceParams) {
      //   try {
      //     localStorage.removeItem('cl.p')
      //     setContext((s) => ({ ...s, sourceParams: null }))
      //   } catch (err) {
      //     console.log(err)
      //     Sentry.captureException(err)
      //   }
      // }

      const t = await r.json()

      trackAction('Callback')
      recordConversion({
        send_to: Conversion.callback,
        // @ts-ignore
        value: 1,
        currency: 'GBP',
      })
      ;(window as any).heap &&
        (window as any).heap.track('Callback Request', {
          method: 'Callback Request',
          service: service.title,
          ...data,
        })

      try {
        const item = klayAction({
          service: props.content[0],
          type: 'view',
        })

        // @ts-ignore
        klaviyo &&
          // @ts-ignore
          klaviyo.push([
            'identify',
            {
              $email: data.email,
              $first_name: data.first_name,
              $last_name: data.last_name,
            },
          ])

        // @ts-ignore
        klaviyo &&
          // @ts-ignore
          klaviyo.push([
            'track',
            'Callback Request',
            {
              ...item,
              $email: data.email,
              $first_name: data.first_name,
              $last_name: data.last_name,
              $phone_number: data.phone,
            },
          ])
      } catch (err) {
        console.log(err)
        Sentry.captureException(err)
      }

      setState({
        ...state,
        loading: false,
        error: null,
        success: true,
        callback: false,
      })
    } catch (err) {
      console.log(err)
      Sentry.captureException(err)
      setState({
        ...state,
        error: true,
        loading: false,
      })
    }
  }

  const image = service.media.filter((x) => x.asset.type.value === 'IMAGE')
  const packages = service.pricing.filter(
    (x) => x.data.type && x.data.type.value === 'PACKAGE'
  )
  
  const pricing = service.pricing.filter((x) => {
    if (service.options && service.options.suppressConsultation) {
      if (x.data.label.includes('Booking')) return false
    }
    return (
      !x.data.label.includes('Patch') &&
      x.data.type &&
      x.data.type.value !== 'PACKAGE'
    )
  })

  if (service.title === 'Anti Wrinkle Injections'){
    console.log('PRICING', pricing)
    return null
  }

  const doScroll = (target) => {
    const el = document.querySelector(target)
    el &&
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      })
  }

  const renderers = {
    p: function P({ children }) {
      return <p className="text-lg mb-0">{children}</p>
    },
    list: function List(props) {
      return (
        <ul className={`grid gap-2 grid-cols-2 md:grid-cols-3 my-3`}>
          {props.children}
        </ul>
      )
    },
    listItem: function Item(props) {
      return <li className="text-sm">{props.children}</li>
    },
  }
  const meta = serviceTemplate(service)
  let faqMeta = null
  if (service.content.faqs) {
    faqMeta = faqTemplate(service.content.faqs)
  }

  return (
    <Layout>
      <Head>
        <title>{service.metadata.title}</title>
        <link
          rel="canonical"
          href={`https://solunalondon.com${props.joinedPath}`}
        />
        <meta name="description" content={service.metadata.description} />
        <link
          rel="alternate"
          hrefLang="en-GB"
          href={`https://solunalondon.com${props.joinedPath}`}
        />
        <link
          rel="alternate"
          hrefLang="en"
          href={`https://solunalondon.com${props.joinedPath}`}
        />
        <link
          rel="alternate"
          hrefLang="x-default"
          href={`https://solunalondon.com${props.joinedPath}`}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: `${JSON.stringify(meta)}` }}
        />
        {faqMeta && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: `${JSON.stringify(faqMeta)}` }}
          />
        )}
      </Head>
      <div className="py-3 shadow relative z-10" id={service.id}>
        <div className="relative max-w-4xl px-4 sm:px-6 lg:max-w-7xl lg:mx-auto lg:px-8 text-gray-700 flex items-center space-x-2 text-xs md:text-base">
          <div>
            <Link href="/treatments/" passHref>
              Treatments
            </Link>
          </div>
          <span>{'>'}</span>
          <div className="hidden md:block">
            <Link href="/treatments/" passHref>

              {service.category.name === 'Dermal Fillers'
                ? 'Injectables'
                : service.category.name}

            </Link>
          </div>
          <span className="hidden md:block">{'>'}</span>
          <div>
            <p>{service.title}</p>
          </div>
        </div>
      </div>
      <div
        className={`py-3 shadow fixed left-0 right-0 bottom-0 z-50 bg-white px-3 ${
          !onScreen ? 'block' : 'hidden'
        } md:hidden`}
      >
        <div className="w-2/3">
          <a
            id="inline-call-button"
            href="tel:+443330163500"
            className="btn-primary phone-number"
          >
            <span className="absolute left-0 inset-y pl-3">
              <Phone className="h-5 w-5 text-brand-500 group-hover:text-brand-400 transition ease-in-out duration-150 fill-current" />
            </span>
            <span>Call us today</span>
          </a>
        </div>
      </div>
      <BackgroundHeader
        heading={service.title}
        text={service.content.callout}
        image="/new-room.png"
      >
        <div className="mt-12">
          {service.attributes.qualities &&
            service.attributes.qualities.length > 0 && (
              <ul className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-6">
                {service.attributes.qualities.map((q, i) => (
                  <li key={`quality-${i}`} className="flex items-center">
                    <div className="text-white bg-brand-500 mr-4 p-2 rounded-lg shadow-md">
                      <svg
                        viewBox="0 0 512 512"
                        xmlns="https://www.w3.org/2000/svg"
                        className="text-white fill-current"
                        width="16"
                      >
                        <path d="m504.5 75.5c-10-10-26.2-10-36.2 0l-306.7 306.7-117.9-117.9a25.6 25.6 0 0 0 -36.2 36.2l136 136c10 10 26.2 10 36.2 0l324.8-324.8c10-10 10-26.2 0-36.2z"></path>
                      </svg>
                    </div>
                    <span className="text-brand-100 sdw">{q}</span>
                  </li>
                ))}
              </ul>
            )}
          {!service.archived && (
            <div
              ref={ctas}
              className="lg:hidden flex flex-col space-y-6 md:space-y-0 md:space-x-8 md:flex-row mt-12 lg:max-w-lg"
            >
              <button
                type="submit"
                onClick={() => addItem(service)}
                className="btn-primary"
              >
                <span className="absolute left-0 inset-y pl-3">
                  <Calendar className="h-5 w-5 text-brand-500 group-hover:text-brand-400 transition ease-in-out duration-150 fill-current" />
                </span>
                <span>Book now</span>
              </button>
              <button
                type="submit"
                className="btn-secondary"
                onClick={() =>
                  setState({
                    ...state,
                    callback: true,
                  })
                }
              >
                <span className="absolute left-0 inset-y pl-3">
                  <Phone className="h-5 w-5 text-brand-500 group-hover:text-brand-400 transition ease-in-out duration-150 fill-current" />
                </span>
                <span>Request a callback</span>
              </button>
            </div>
          )}
        </div>
      </BackgroundHeader>

      <nav
        className="mx-4 relative md:sticky top-0 z-10 max-w-7xl lg:mx-auto rounded-md bg-white -mt-11 shadow flex flex-col justify-center"
        style={{ top: -1 }}
      >
        <div className="flex flex-col md:flex-row md:space-x-8 px-8 text-xl py-3">
          <div className="flex flex-col md:flex-1 md:flex-row space-y-4 md:space-y-0 md:space-x-8">
            <button
              className="font-serif text-left rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
              onClick={() => doScroll('#information')}
            >
              Information
            </button>
            <button
              className="font-serif text-left rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
              onClick={() => doScroll('#pricing')}
            >
              Pricing
            </button>
            {packages.length > 0 && (
              <button
                className="font-serif text-left rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
                onClick={() => doScroll('#packages')}
              >
                Packages
              </button>
            )}
            {service.content.faqs && service.content.faqs.length > 0 && (
              <button
                className="font-serif text-left rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
                onClick={() => doScroll('#faqs')}
              >
                Frequently Asked Questions
              </button>
            )}
          </div>
          {!service.archived && (
            <div className="hidden lg:flex flex-col flex-1 space-y-6 md:space-y-0 md:space-x-8 md:flex-row transition-all duration-150">
              <button
                type="submit"
                onClick={() => addItem(service)}
                className="group relative w-full font-serif flex items-center justify-center px-4 py-3 border border-transparent rounded-md md:py-4 md:text-lg md:px-10 tracking-wide text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
              >
                <span className="absolute left-0 inset-y pl-3">
                  <Calendar className="h-5 w-5 text-brand-500 group-hover:text-brand-400 transition ease-in-out duration-150 fill-current" />
                </span>
                <span className="sm:text-sm xl:text-lg">Book now</span>
              </button>
              <button
                type="submit"
                onClick={() =>
                  setState({
                    ...state,
                    callback: true,
                  })
                }
                className="group relative w-full font-serif flex items-center justify-center px-4 py-3 border rounded-md md:py-4 md:text-lg md:px-10 tracking-wide shadow text-gray-800 bg-white hover:bg-gray-100 border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
              >
                <span className="absolute left-0 inset-y pl-3">
                  <Phone className="h-5 w-5 text-brand-500 group-hover:text-brand-400 transition ease-in-out duration-150 fill-current" />
                </span>
                <span className="sm:text-sm xl:text-lg">
                  Request a callback
                </span>
              </button>
            </div>
          )}
        </div>
      </nav>

      <section id="information" className="bg-white overflow-hidden">
        <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen"></div>
          <div className="relative mx-auto text-base max-w-prose lg:max-w-none">
            <h3 className="text-base text-brand-600 font-semibold tracking-wide uppercase">
              {service.category.name === 'Dermal Fillers'
                ? 'Injectables'
                : service.category.name}
            </h3>
            <h2 className="mt-2 text-3xl leading-8 font-serif text-gray-900 sm:text-4xl">
              {service.content.intro}
            </h2>
          </div>
          <div className="relative mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="relative lg:row-start-1 lg:col-start-2">
              <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                {image && image[0] && (
                  <figure>
                    <div className="aspect-w-12 aspect-h-7 lg:aspect-h-7">
                      <Image
                        src={image[0].asset.url}
                        alt={service.content.callout || service.title}
                        className="rounded-lg shadow-lg object-cover object-center h-full"
                        fill
                        sizes="100vw" />
                    </div>
                    <figcaption className="mt-3 flex text-sm text-gray-600">
                      <span className="ml-2">
                        {service.title} at Soluna London
                      </span>
                    </figcaption>
                  </figure>
                )}
              </div>
            </div>
            <div className="relative mt-8 lg:mt-0">
              {service.content.highlight && (
                <div className="text-base max-w-prose mx-auto lg:max-w-none">
                  <p className="text-xl text-gray-800">
                    {service.content.highlight}
                  </p>
                </div>
              )}
              <div
                className={`${
                  service.content.highlight ? 'mt-5' : ''
                } prose prose-brand text-gray-600 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1`}
              >
                <Markdown
                  source={service.content.body}
                  renderers={{
                    heading: function H({ children }) {
                      return (
                        <h3 className="font-serif text-2xl mb-4">{children}</h3>
                      )
                    },
                    img: function Img(props) {
                      return (
                        <span className="block relative aspect-w-12 aspect-h-8">
                          <LocalImage
                            className="rounded-md shadow-md object-fill"
                            src={props.src}
                            layout="fill"
                            alt={service.content.highlight}
                          />
                        </span>
                      )
                    },
                  }}
                />
              </div>
              {service.content.priceMatch &&
                service.content.priceMatch.heading && (
                  <div className="relative flex flex-col rounded-lg shadow-lg overflow-hidden">
                    <h3 className="text-center font-serif text-2xl mb-4">
                      {service.content.priceMatch.heading}
                    </h3>
                    <Markdown
                      className="leading-loose text-lg"
                      source={service.content.priceMatch.body}
                      renderers={{
                        heading: function H({ children }) {
                          return (
                            <h3 className="text-center font-serif text-2xl mb-4">
                              {children}
                            </h3>
                          )
                        },
                      }}
                    />
                  </div>
                )}
            </div>
          </div>
        </div>
      </section>

      {packages && packages.length > 0 && (
        <section id="packages" className="relative w-full overflow-x-hidden">
          <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
            <div className="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen"></div>
            <div className="mx-auto text-base max-w-prose lg:max-w-none">
              <h2 className="text-3xl leading-8 font-serif text-gray-900 sm:text-4xl">
                Packages
              </h2>
              <p className="text-gray-700 mt-3 text-lg">
                Our packages combine treatment options to maximise the value
                provided even further
              </p>
            </div>
            <div className="mt-8 relative bg-white lg:bg-transparent grid grid-cols-1 lg:gap-8 md:grid-cols-2 lg:grid-cols-3 mx-auto text-base max-w-prose lg:max-w-none">
              {packages.map(({ data }, i) => {
                return (
                  <div
                    id={`package-${data.id}`}
                    key={`package-${data.id}-${i}`}
                    className={`bg-white border border-brand-300 p-6 flex flex-col lg:rounded lg:shadow ${
                      i === 0 ? 'rounded-t-md' : ''
                    } ${i === packages.length - 1 ? 'rounded-b-md' : ''}`}
                  >
                    <p className="font-serif text-xl flex-1">{data.label}</p>
                    <div className="mt-3 grid gap-4 grid-flow-col">
                      {data.options.map((option) => (
                        <div key={option.id}>
                          <Markdown
                            source={option.data.label}
                            renderers={renderers}
                          />
                          <p className="font-serif text-xl">
                            <span className="text-sm">£</span>
                            {option.data.price / 100}
                          </p>
                          {option.data.was && option.data.was > 0 ? (
                            <p className="font-serif text-base">
                              <span className="text-sm">Was: </span>
                              <span className="text-sm">£</span>
                              {option.data.was / 100}
                            </p>
                          ) : null}
                        </div>
                      ))}
                    </div>
                    <div className="w-full h-12">
                      <Klarna
                        data-key="credit-promotion-small"
                        data-locale="en-GB"
                        data-purchase-amount={
                          data.options[data.options.length - 1].data.price
                        }
                      ></Klarna>
                    </div>
                  </div>
                )
              })}
            </div>
            {!service.archived && (
              <div className="mt-8 flex flex-col space-y-6 md:flex-row md:space-y-0 md:space-x-6 md:mt-16 lg:hidden mx-auto text-base max-w-prose lg:max-w-none">
                <button
                  type="submit"
                  onClick={() => addItem(service)}
                  className="btn-primary"
                >
                  <span className="absolute left-0 inset-y pl-3">
                    <Calendar className="h-5 w-5 text-brand-500 group-hover:text-brand-400 transition ease-in-out duration-150 fill-current" />
                  </span>
                  <span className="sm:text-sm xl:text-lg">Book now</span>
                </button>
                <button
                  type="submit"
                  className="btn-secondary"
                  onClick={() =>
                    setState({
                      ...state,
                      callback: true,
                    })
                  }
                >
                  <span className="absolute left-0 inset-y pl-3">
                    <Phone className="h-5 w-5 text-brand-500 group-hover:text-brand-400 transition ease-in-out duration-150 fill-current" />
                  </span>
                  <span className="sm:text-sm xl:text-lg">
                    Request a callback
                  </span>
                </button>
              </div>
            )}
          </div>
        </section>
      )}

      <section
        id="pricing"
        className={`relative w-full overflow-x-hidden ${
          !service.content.faqs || service.content.faqs.length === 0
            ? 'pb-24'
            : ''
        }`}
      >
        <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
          <div className="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen"></div>
          <div className="mx-auto text-base max-w-prose lg:max-w-none">
            <h2 className="text-3xl leading-8 font-serif text-gray-900 sm:text-4xl">
              Pricing
            </h2>
          </div>
          <div className="mt-8 relative bg-white lg:bg-transparent grid lg:gap-8 lg:grid-cols-2 mx-auto text-base max-w-prose lg:max-w-none">
            {pricing && pricing.map(({ data }, i) => {
              if (!data) return null;
              const grid = data && data.options && data.options.length > 0
              const target = grid
                ? data.options[data.options.length - 1].data.price
                : data
                  ? data.price
                  : null

              return (
                <div
                  id={`pricing-${data.id}`}
                  key={`${data.id}-p-${i}`}
                  className={`bg-white border border-brand-300 p-6 flex flex-col lg:rounded lg:shadow ${
                    i === 0 ? 'rounded-t-md' : ''
                  } ${i === pricing.length - 1 ? 'rounded-b-md' : ''} ${
                    grid ? 'with-opts' : 'standalone'
                  }`}
                >
                  <div
                    className={`font-serif text-xl sm:mb-0 flex-1 ${
                      !grid ? 'gap-2 grid grid-cols-2' : ''
                    }`}
                  >
                    <Markdown source={data.label} renderers={renderers} />
                    {!grid && (
                      <p className="font-serif text-xl gap-4 grid grid-cols-2">
                        <span>
                          <span className="text-sm">£</span>
                          {data.price / 100}
                        </span>
                        {data && data.was && data.was > 0 ? (
                          <span className="font-serif text-base">
                            <span className="text-sm">Was: </span>
                            <span className="text-sm">£</span>
                            {data.was / 100}
                          </span>
                        ) : null}
                      </p>
                    )}
                  </div>
                  {grid && (
                    <div className="mt-3 gap-4 grid md:grid-cols-3">
                      {data && data.options && data.options.map((option, i) => (
                        <div key={`${option.id}-${i}`}>
                          <Markdown
                            source={option.data.label}
                            renderers={renderers}
                          />
                          <p className="font-serif text-xl">
                            <span className="text-sm">£</span>
                            {option.data.price / 100}
                          </p>
                          {option.data.was && option.data.was > 0 ? (
                            <p className="font-serif text-base">
                              <span className="text-sm">Was: </span>
                              <span className="text-sm">£</span>
                              {option.data.was / 100}
                            </p>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  )}
                  {target > 9900 && (
                    <div
                      className="w-full h-12 mx-auto"
                      style={{ maxWidth: 335 }}
                    >
                      <Klarna
                        data-key="credit-promotion-small"
                        data-locale="en-GB"
                        data-purchase-amount={target}
                      />
                    </div>
                  )}
                </div>
              )
            })}
          </div>
          {!service.archived && (
            <div className="mt-8 flex flex-col space-y-6 md:flex-row md:space-y-0 md:space-x-6 md:mt-16 lg:hidden mx-auto text-base max-w-prose lg:max-w-none">
              <button
                type="submit"
                onClick={() => addItem(service)}
                className="btn-primary"
              >
                <span className="absolute left-0 inset-y pl-3">
                  <Calendar className="h-5 w-5 text-brand-500 group-hover:text-brand-400 transition ease-in-out duration-150 fill-current" />
                </span>
                <span className="sm:text-sm xl:text-lg">Book now</span>
              </button>
              <button
                type="submit"
                className="btn-secondary"
                onClick={() =>
                  setState({
                    ...state,
                    callback: true,
                  })
                }
              >
                <span className="absolute left-0 inset-y pl-3">
                  <Phone className="h-5 w-5 text-brand-500 group-hover:text-brand-400 transition ease-in-out duration-150 fill-current" />
                </span>
                <span className="sm:text-sm xl:text-lg">
                  Request a callback
                </span>
              </button>
            </div>
          )}
        </div>
      </section>

      {service.content.faqs && service.content.faqs.length > 0 && (
        <section
          id="faqs"
          className="relative max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8 pb-24"
        >
          <div className="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen"></div>
          <div className="mx-auto text-base max-w-prose lg:max-w-none mb-12">
            <h2 className="text-3xl leading-8 font-serif text-gray-900 sm:text-4xl">
              Frequently asked questions
            </h2>
          </div>
          <div className="relative">
            {service.content.faqs.map((faq, i) => (
              <FAQ {...faq} key={`faq-${i}`} />
            ))}
          </div>
        </section>
      )}
      <Modal
        open={state.callback}
        close={() => setState({ ...state, callback: false })}
      >
        <div className="w-72 mx-auto">
          <form onSubmit={handleSubmit(handle)} className="space-y-3">
            <h3 className="font-serif text-xl text-center">
              Request a callback
            </h3>
            <label htmlFor="name" className="block">
              <input
                id="name"
                name="first_name"
                type="text"
                placeholder="Enter your name"
                className="appearance-none relative block w-full px-4 py-4 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:shadow-outline-brand focus:z-10 sm:text-sm sm:leading-5 focus:outline-none focus:ring-brand-500 focus:border-brand-500 focus:placeholder-gray-400"
                {...register('first_name', { required: true })}
              />
            </label>
            {errors.first_name && (
              <p className="text-red-500">Name is required</p>
            )}
            <label htmlFor="surname" className="block">
              <input
                id="surname"
                name="last_name"
                type="text"
                placeholder="Enter your surname"
                className="appearance-none relative block w-full px-4 py-4 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:shadow-outline-brand focus:z-10 sm:text-sm sm:leading-5 focus:outline-none focus:ring-brand-500 focus:border-brand-500 focus:placeholder-gray-400"
                {...register('last_name', { required: true })}
              />
            </label>
            {errors.last_name && (
              <p className="text-red-500">Surname is required</p>
            )}
            <label htmlFor="phone" className="block">
              <input
                id="phone"
                name="phone"
                type="tel"
                placeholder="Enter your phone number"
                className="appearance-none relative block w-full px-4 py-4 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:shadow-outline-brand focus:z-10 sm:text-sm sm:leading-5 focus:outline-none focus:ring-brand-500 focus:border-brand-500 focus:placeholder-gray-400"
                {...register('phone', { required: true })}
              />
            </label>
            {errors.phone && (
              <p className="text-red-500">Phone number is required</p>
            )}
            <label htmlFor="email" className="block">
              <input
                id="email"
                name="email"
                type="email"
                placeholder="Enter your email"
                className="appearance-none relative block w-full px-4 py-4 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:shadow-outline-brand focus:z-10 sm:text-sm sm:leading-5 focus:outline-none focus:ring-brand-500 focus:border-brand-500 focus:placeholder-gray-400"
                {...register('email', { required: true })}
              />
            </label>
            {errors.email && <p className="text-red-500">Email is required</p>}
            <button
              type="submit"
              className="btn-primary"
              disabled={state.loading}
            >
              Submit
            </button>
          </form>
        </div>
      </Modal>
      <Modal
        open={state.success}
        close={() => setState({ ...state, success: false })}
      >
        <div className="w-72 mx-auto">
          <h3 className="font-serif text-xl text-center">
            Your details have been submitted!
          </h3>
          <p>
            Thank you for your interest in Soluna London. We will be in touch
            with you by phone or email shortly.
          </p>
        </div>
      </Modal>
    </Layout>
  );
}

export const getStaticProps: GetStaticProps = async (context) => {
  const { params } = context
  const query = serviceById

  const client = ApiClient()

  const joinedPath = `/${(params.page as string[]).join('/')}/`

  let pageData = null

  try {
    pageData = await client.query({
      query,
      variables: {
        path: joinedPath,
      },
    })
  } catch (e) {
    Sentry.captureException(e)
  }

  return {
    revalidate: 60 * 60,
    props: {
      joinedPath,
      ...pageData.data,
    },
  }
}

export const getStaticPaths: GetStaticPaths = async () => {
  const client = ApiClient()

  const serviceData = await client.query({
    query: servicePaths,
  })

  const pathData = []

  serviceData.data.service.forEach(({ metadata }) => {
    if (metadata && metadata.path) {
      const pageParams =
        metadata.path === '/'
          ? [metadata.path]
          : metadata.path.split('/').filter(Boolean)
      pathData.push({
        params: {
          page: pageParams,
        },
      })
    }
  })

  return {
    paths: pathData,
    fallback: true,
  }
}
