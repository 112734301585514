import { Product, Service , FAQ} from '../interfaces';

export const productTemplate = (product: Product) => {
  const image = product.media.filter((x) => x.asset.type.value === 'IMAGE')
  const data = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": product.title,
    "image": image && image.length > 0
      ? image.map(i => i.asset.url.startsWith('http') ? i.asset.url : `https://solunalondon.com${i.asset.url}`)
      : "",
    "description": product.metadata ? product.metadata.description : '',
    "url": `https://solunalondon.com.com${Array.isArray(product.metadata.path) ? product.metadata.path[0]  : product.metadata.path}`,
    "brand": {
      "@type": "Brand",
      "name": "SkinCeuticals"
    },
    "offers": {
      "@type": "Offer",
      "url": `https://solunalondon.com.com${Array.isArray(product.metadata.path) ? product.metadata.path[0]  : product.metadata.path}`,
      "priceCurrency": "GBP",
      "price": (product.pricing[0].data.price / 100),
      "priceValidUntil": "2020-12-31",
      "itemCondition": "https://schema.org/NewCondition",
      "availability": "https://schema.org/InStock",
      "seller": {
        "@type": "Organization",
        "name": "Soluna London"
      }
    }
  }
  if (product.gtin) {
    // @ts-ignore
    data.gtin = product.gtin
  }
  if (product.sku) {
    // @ts-ignore
    data.sku = product.sku
  }
  return data
}

export const serviceTemplate = (service: Service) => {
  if (!service || service && service.pricing.length === 0) {
    return {}
  }
  const image = service.media && service.media.filter((x) => x.asset.type.value === 'IMAGE')
  return {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": service.title,
    "image": image && image.length > 0
      ? image.map(i => i.asset.url.startsWith('http') ? i.asset.url : `https://solunalondon.com${i.asset.url}`)
      : "",
    "description": service.metadata.description,
    "url": `https://solunalondon.com.com${Array.isArray(service.metadata.path) ? service.metadata.path[0]  : service.metadata.path}`,
    "brand": {
      "@type": "Brand",
      "name": "Soluna London"
    },
    "offers": {
      "@type": "AggregateOffer",
      "url": `https://solunalondon.com${Array.isArray(service.metadata.path) ? service.metadata.path[0]  : service.metadata.path}`,
      "priceCurrency": "GBP",
      "lowPrice": (service.pricing[0].data.price / 100),
      "highPrice": (service.pricing[service.pricing.length - 1].data.price / 100),
      "offerCount": service.pricing.length
    }
  }
}

export const faqTemplate = (faqs: FAQ[]) => {
  return {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": faqs.map(f => ({
      "@type": "Question",
      "name": f.heading,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": f.body
      }
    }))
  }
}
